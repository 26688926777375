import { AnimatedIconNames } from 'enums/animatedIconNames'

type AnimationDefaultsForIcon = {
  [keys in AnimatedIconNames]?: {
    /**
     * Defaults to normal speed if undefined.
     */
    speed?: number
    /**
     * Defaults to the last frame if undefined.
     * Visit `lottiefiles.com/preview` to easily find out the frame number the animation should freeze if the last frame isn't enough.
     */
    lastFrameToFreezeOn?: number
  }
}

/**
 * Most of the icons don't need extra configuration because these values
 * can be determined by the JSON file provided for each animation.
 *
 * However, some icons need a custom configuration because we might want to
 * change the speed or what is the frame that determines the end of the animation.
 *
 * This file doesn't belong to the shared folder because each project might need
 * configurations. Or they might have different icons with the same name which
 * they might demand a different configuration as well.
 */
export const ICON_ANIMATION_CONFIG: AnimationDefaultsForIcon = {
  check: {
    speed: 0.4,
    lastFrameToFreezeOn: 15
  }
}

export const MOCKED_ANIMATED_ICON = ''
